import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../layout/layout'
import SEO from '../components/seo'
import {
  Intro,
  Desc,
  // Map,
  Stats,
  Presentation,
  Visual,
  Form,
  MapHTML,
} from '../components/Index'

const IndexPage = () => {
  const {
    seo: {
      frontmatter: { seo_title, seo_description, seo_image },
    },
  } = useStaticQuery(graphql`
    query {
      seo: markdownRemark(
        fileAbsolutePath: { regex: "/content/seo/index.md/" }
      ) {
        frontmatter {
          seo_description
          seo_page
          seo_title
          seo_image {
            publicURL
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO
        title={seo_title}
        description={seo_description}
        image={seo_image?.publicURL}
      />
      <Intro />
      <Desc />
      <MapHTML />
      <Stats />
      <Presentation>
        <Visual />
      </Presentation>
      <Form />
    </Layout>
  )
}

export default IndexPage
